<template>
  <loader :object="styling.objectcolor" :color1="styling.animationcolor" :color2="styling.animationcolor2"
    :size="styling.size" :speed="styling.speed" :bg="styling.bgcolor" :objectbg="styling.objectbg"
    :opacity="styling.opacity" :name="selected" :disableScrolling="styling.disableScrolling">
  </loader>
  <br><br>
  <div class="fixed-top">
    <Header />
    <div id="tophead" v-if="showheadDiv">
      <div class="header-bot-sec">
        <div class="container">
          <div class="row">
            <div class="rdo">
              <form action="#">
                <p class="first-rasecc">
                  <input type="radio" id="quote" name="radio-group" :checked="isQuote" disabled />
                  <label for="quote" v-bind:class="{ active: isQuote }"><span>QUOTE</span></label>
                </p>
                <p class="first-rasecc1">
                  <input type="radio" id="userinfo" name="radio-group" :checked="isinfo" disabled />
                  <label for="userinfo" v-bind:class="{ active: isinfo }"><span>YOUR INFO</span></label>
                </p>
                <p class="first-rasecc2">
                  <input type="radio" id="payment" name="radio-group" :checked="ispayment" disabled />
                  <label for="payment" v-bind:class="{ active: ispayment }"><span>PAYMENT</span></label>
                </p>
                <p class="first-rasecc3">
                  <input type="radio" id="wellcome" name="radio-group" disabled />
                  <label for="wellcome"><span>WELCOME</span></label>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="Monthly-Head">
        <div class="container">
          <div class="row">
            <p>
              Monthly Quote Total:
              <span class="monthly-quote-amount">${{ QuoteTotal.toFixed(2) }}</span>
              &nbsp;<span>(taxes extra)</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <slot />
  <form @submit.prevent.stop="submitForm(quote)">
    <div class="container DetailsForm-Page">
      <div class="row">
        <h2>Your Details</h2>

        <div class="row Main-Details-Sec">
          <div class="col-md-4">
            <label>Name*</label>
          </div>

          <div class="col-md-4">
            <input type="text" class="form-control Input-FieldCH" placeholder="First Name" v-model="quote.firstname" />
            
          </div>

          <!-- <div class="col-md-4">
            <label>Last Name*</label>
          </div> -->
          <div class="col-md-4">
            <input type="text" class="form-control Input-FieldCH" placeholder="Last Name" v-model="quote.Lastname" />
          </div>

          <div class="col-md-4">
            <label>Address*</label>
          </div>

          <div class="col-md-8">
            <input type="text" class="form-control Input-FieldCH" placeholder="Address" v-model="quote.Address" />
          </div>

          <div class="col-md-4"></div>

          <div class="col-md-8">
            <input type="text" class="form-control Input-FieldCH" placeholder="Address 2" v-model="quote.Address2" />
          </div>

          <div class="col-md-4">
            <label>City*</label>
          </div>

          <div class="col-md-8">
            <input type="text" class="form-control Input-FieldCH" placeholder="City" v-model="quote.City" />
          </div>

          <div class="col-md-4"></div>

          <div class="col-md-8">
            <div class="row City-Inner">
              <div class="col-md-6">
                <div class="Choose-MethodSec">
                  <select v-model="quote.province" class="form-control" @change="onChange($event)">
                    <option>Select Province</option>
                    <option v-for="(province, index, key) in provinces" :index="index" :key="key"
                      v-bind:value="province.provinceId">{{province.provinceValue}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <input type="text" class="form-control Input-FieldCH" placeholder="Postal Code" autocomplete="false"
                  disabled v-model="quote.cityCode" />
                <!-- <vue-mask 
           class="form-control Input-FieldCH" 
           v-model="quote.cityCode" placeholder="A1A 1A1" @input="upper($event)" 
           mask="AAA AAA" 
           pattern="^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$"
           :options="options" > 
          </vue-mask> -->
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <label>
              Mobile Phone*
              <!-- <br />(for SMS Messaging) -->
            </label>
          </div>

          <div class="col-md-8">
            <!-- <input
              type="text"
              class="form-control Input-FieldCH"
              placeholder
              v-model="quote.phone"
              @keypress="isNumber($event)"
            /> -->
            <vue-mask class="form-control Input-FieldCH" v-model="quote.phone" mask="000-000-0000"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" :options="options">
            </vue-mask>
          </div>

          <!-- <div class="col-md-4">
            <label>Other Phone</label>
          </div>

          <div class="col-md-8">
           
            <vue-mask class="form-control Input-FieldCH" v-model="quote.phoneAlt" mask="000-000-0000"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" :options="options">
            </vue-mask>
          </div> -->
        </div>

        <!-- <h2 style="margin-bottom: 20px;">Additional Owner <small>(Optional)</small></h2>    
        <div class="row Main-Details-Sec">
          <div class="col-md-4"></div>
            <div class="col-md-8 AdditionalPet-Section">
           <div class="CheckBox-Cir">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"                     
                  name="radio"
                  id="AdditionalPetowner_True" 
                  :selected="quote.AdditionalPetowner=== true"
                   @click="ShowHideDiv()"/>
                <label class="form-check-label" for="inlineRadioOptionspetExamined1">Yes</label>
              </div>
              <div class="form-check form-check-inline">
               <input
                  class="form-check-input"
                  type="radio"                   
                  name="radio"   
                  id="AdditionalPetowner_False"            
                  :selected="quote.AdditionalPetowner=== false"                  
                  @click="HideDiv()"/> 
                <label class="form-check-label" for="inlineRadioOptionspetExamined1_">No</label>
              </div>
          </div>  
          </div>
            <div class="row pt-3" id="displaycheck" v-if="quote.AdditionalPetowner">
          <div class="col-md-4">
            <label style="margin-top: 0px;">Name</label>
          </div>
          <div class="col-md-4">
            <input type="text" class="form-control Input-FieldCH" placeholder="First Name"
              v-model="quote.SecondaryFirstName" />
          </div>
          <div class="col-md-4">
            <input type="text" class="form-control Input-FieldCH" placeholder="Last Name"
              v-model="quote.SecondaryLastName" />
          </div>
          <div class="col-md-4"></div>
         <div class="col-md-8">
            <p class="additional-owner-text">This person has permission to make inquiries regarding the status of the
              policy or a claim, and/or update payment information on the account.</p>
          </div>
        </div>
       </div> -->


        <div class="Add-Another-ExpandContact collapse" id="demo">
          <h2>Second Owner Details</h2>
          <div class="row Main-Details-Sec">
            <div class="col-md-4">
              <label>First Name</label>
            </div>

            <div class="col-md-8">
              <input type="text" class="form-control Input-FieldCH" placeholder v-model="quote.SecondaryFirstName" />
            </div>

            <div class="col-md-4">
              <label>Last Name</label>
            </div>

            <div class="col-md-8">
              <input type="text" class="form-control Input-FieldCH" placeholder v-model="quote.SecondaryLastName" />
            </div>

            <div class="col-md-4">
              <label>Email</label>
            </div>

            <div class="col-md-8">
              <input type="text" class="form-control Input-FieldCH" placeholder v-model="quote.SecondaryEmail" />
            </div>

            <div class="col-md-4">
              <label>
                Mobile Phone
                <br />(for SMS Messaging)
              </label>
            </div>

            <div class="col-md-8">
              <input type="text" class="form-control Input-FieldCH" placeholder v-model="quote.SecondaryMobilePhone" />
            </div>

            <div class="col-md-4">
              <label>Other Phone (Optional)</label>
            </div>

            <div class="col-md-8">
              <input type="text" class="form-control Input-FieldCH" placeholder v-model="quote.SecondaryHomePhone" />
            </div>

            <div class="col-md-4"></div>
          </div>
        </div>

        <h2>Your Pet Health Details</h2>

        <div class="row Health-Details-Inner" v-for="(item, index, key) in petsInfo" :index="index" :key="key">
          <div class="col-md-5">
            <h3>
              <span>For</span>
              {{ item.petInfo.petName }}
            </h3>
            <div class="clear-benefits-section" v-for="(plan, index, key) in item.policyList" :index="index" :key="key">
              <div v-if="plan.selected===true">
                <h4>{{plan.productName}} / ${{ plan.productName==="Direct 5"? "5,000":"10,000" }} Annual Benefits</h4>
                <p>${{ plan.productName==="Direct 5"? "200":"200" }} deductible / 80% reimbursement on all plans.</p>
              </div>
            </div>
          </div>
          <div class="col-md-7 Health-Label">

            <!-- <label>Your Pet's Veterinarian*</label>
          <div class="clearfix"></div> -->

            <!-- <div v-if="item.questions[4].selected" :id="'petDistance_'+index" :ref="'petDistance_'+index">


             <div class="form-group has-search" data-toggle="collapse" data-bs-target="#demo3">
              <i class="fa fa-search"></i>
              <input
                type="text"
                class="form-control Input-FieldCH"
                :name="'vetName'+index"
                :id="'vetName'+index"
                v-model="item.questions[2].VetName"
                :minlength="5"
                v-on:keyup="Vetsearch(item.questions[2].VetName)" v-on:keydown="Vetsearch(item.questions[2].VetName)"
                v-on:focus="showlist()"
                autocomplete="off"
                
              />



              <div class="practice-list-holder" id="demo3" v-if="showDiv && veterinarians.length">
                <ul>
                  <li
                   v-for="(vet, key) in veterinarians"
                    :key="key"
                    :value="vet.id"
                    :title="[vet.practiceName]"
                    @click="setVetcode(vet,index)"
                  >
                  <a @click="setVetcode(vet,index)">{{ vet.practiceName }} </a>
                   <br><small>
                      {{ vet.address1 }}, {{ vet.townCity }}, {{ vet.regionCounty }}, {{ vet.postalCode }}
                    </small>
                  </li>
                 
                </ul>
              </div>

              
            </div>
          </div> -->



            <!-- <div class="form-check check-ext YourInfo-Sec" v-if="index > 0">
              <input type="checkbox" class="form-check-input"
               :name="'inlineCheck1_'+index"
               :id="'inlineCheck1_'+index"
               v-model="item.questions[4].selected"
               :selected="item.questions[4].selected === false"
               @change="onChangeChekBox($event,index)" />
              <label class="form-check-label" for="exampleCheck1">Different Than Above</label>
            </div>

   <div class="form-check check-ext YourInfo-Sec" v-if="index > 0">
              <input type="checkbox" class="form-check-input"
               :name="'other'+index"
                :id="'other'+index"
                v-model="item.questions[4].other"
               :selected="item.questions[4].other === true"
               @click="setVetcode('41046',index)"
                @change="onChangeChekBoxother($event,index)"
              />
              <label class="form-check-label" for="other">My vet clinic is not listed / I do not have a primary vet clinic</label>
            </div> -->


            <!-- <div class="form-group has-search"  data-toggle="collapse" data-bs-target="#demo2" v-if="index == 0">
              <i class="fa fa-search"></i>
               <div id="demo2n">
              <input
                type="text"
                class="form-control Input-FieldCH"
                :name="'vetName'+index"
                :id="'vetName'+index"
                v-model="item.questions[2].VetName"
                 :minlength="5"
                v-on:keyup="Vetsearch(item.questions[2].VetName)" v-on:keydown="Vetsearch(item.questions[2].VetName)"
                v-on:focus="showlist()"
                autocomplete="off"
              />

             
            
            
              <div class="practice-list-holder" id="demo2"  v-if="showDiv && veterinarians.length">
                <ul>
                  <li
                    v-for="(vet, key) in veterinarians"
                    :key="key"
                    :value="vet.id"
                    :title="[vet.practiceName]"
                    @click="setVetcode(vet,index)"
                  >
                    <a @click="setVetcode(vet,index)">{{ vet.practiceName }}

                    <br><small>
                      {{ vet.address1 }}, {{ vet.townCity }}, {{ vet.regionCounty }}, {{ vet.postalCode }}
                    </small>
                    </a>
                  </li>
                </ul>
              </div>

                <div class="form-check check-ext YourInfo-Sec" v-if="index == 0">
              <input type="checkbox" class="form-check-input"
               :name="'other'+index"
                :id="'other'+index"
               v-model="item.questions[4].other"
                :selected="item.questions[4].other === true"
               @click="setVetcode('41046',index)"
                @change="onChangeChekBoxotherup($event,index)"
              />
              <label class="form-check-label" for="other">My vet clinic is not listed / I do not have a primary vet clinic</label>
            </div>
            </div>
            </div> -->

            <!-- <div class="form-check check-ext YourInfo-Sec" v-if="index > 0">
              <input type="checkbox" class="form-check-input"
               :name="other"
               :id="other"
               v-model="other"
               :selected="other"
              />
              <label class="form-check-label" for="other">My Vet clinic is not listed / I do not have a primary vet clinic</label>
            </div> -->


            <label>Health Questions*</label>
            <p>
              Has your pet been examined by a veterinarian in the last 12
              months?
            </p>

            <div class="CheckBox-Cir">

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" :name="'inlineRadioOptionspetExamined1_'+ index"
                  :id="'inlineRadiopetExamined1_'+index" :selected="item.questions[0].selected === true"
                  v-model="item.questions[0].selected" value=5144 />
                <label class="form-check-label" for="inlineRadioOptionspetExamined1">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" :name="'inlineRadioOptionspetExamined1_'+index"
                  :id="'inlineRadiopetExamined2_'+index" :selected="item.questions[0].selected === false"
                  v-model="item.questions[0].selected" value=5145 />
                <label class="form-check-label" for="inlineRadioOptionspetExamined1_">No</label>
              </div>
            </div>
            <p>
              Are you aware of any medical conditions now or in the past
              involving your pet that did or could require treatment from a
              veterinarian?
            </p>

            <div class="CheckBox-Cir">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" :name="'inlineRadioOptionspettreatment1_'+index"
                  :id="'inlineRadiopettreatmen1_'+index" @click="onChangeTreatmentYes($event,index)"
                  :selected="item.questions[1].selected === true" v-model="item.questions[1].selected" value=5144 />
                <label class="form-check-label" for="inlineRadioOptionspettreatment1">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" :name="'inlineRadioOptionspettreatment1_'+index"
                  :id="'inlineRadiopettreatmen2_'+ index" @click="onChangeTreatmentNo(index)"
                  :selected="item.questions[1].selected === false" v-model="item.questions[1].selected" value=5145 />
                <label class="form-check-label" for="inlineRadioOptionspettreatment2">No</label>
              </div>


            </div>

            <br>

            <div :id="'dvtext_'+index" :ref="'dvtext_'+index" v-if="item.questions[1].selected==='5144'">
              <input type="text" id="txtBox" class="form-control Input-FieldCH" v-model="item.questions[1].messageIfYes"
                placeholder="Please provide your pet's medical information here.." autocomplete="off" required />
            </div>




          </div>


        </div>

        <div class="row" style="width:100%;margin: 0;">
          <div class="col-md-2"></div>
          <div class="col-md-8 error-field">
            <p v-if="errors.length">
              <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="(errorr, key) in errors" :key="key" style="color:red;text-align: left;">{{ errorr }}</li>
            </ul>
            </p>
          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="col-md-12">
          <div class="btn-Submit">
            <button type="submit" class="btn btn-primary">CONTINUE</button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <p class="back-quote">
    <a href="/quote" @click="removeQuestion">&lt;&lt; Back to Quote</a>
  </p>
</template>

<script>
import axios from "axios";
import { client as config } from "../config";
import Header from "../components/header.vue";
export default {
  components: {
    Header,
  },
  name: "quoteDetailsForm",
  title: "Get PHI Direct – Your Information",
  props: {
    onSuccess: Function,
  },
  data: () => ({
    options: {
      placeholder: "555-555-5555",
    },
    errors: [],
    isQuote: false,
    isinfo: true,
    ispayment: false,
    showheadDiv: true,
    QuoteTotal: 0,
    savedQuoteKey: "",
    quote: { cityCode: "", province: "Select Province", month: "0", year: "0" },
    infos: {},
    veterinarians: [],
    petsInfo: [],
    allveters: [],
    error: null,
    showDiv: false,
    IsEmail: true,
    treatmentValue: "",
    PostalCodes: "",
    styling: {
      objectcolor: "#ff9633",
      animationcolor: "#ffffff",
      animationcolor2: "#17fd3d",
      objectbg: "#999793",
      opacity: 80,
      bgcolor: "#343a40",
      size: 5,
      speed: 2,
      disableScrolling: false,
    },
    provinces: [
      {
        provinceId: "Alberta",
        provinceValue: "AB",
      },
      {
        provinceId: "British Columbia",
        provinceValue: "BC",
      },
      {
        provinceId: "Manitoba",
        provinceValue: "MB",
      },
      {
        provinceId: "New Brunswick",
        provinceValue: "NB",
      },
      {
        provinceId: "Newfoundland and Labrador",
        provinceValue: "NL",
      },
      {
        provinceId: "Northwest Territories",
        provinceValue: "NT",
      },
      {
        provinceId: "Nova Scotia",
        provinceValue: "NS",
      },
      {
        provinceId: "Nunavut",
        provinceValue: "NU",
      },
      {
        provinceId: "Ontario",
        provinceValue: "ON",
      },
      {
        provinceId: "Prince Edward Island",
        provinceValue: "PE",
      },
      {
        provinceId: "Saskatchewan",
        provinceValue: "SK",
      },
      {
        provinceId: "Yukon",
        provinceValue: "YT",
      },
    ],
    selectData: false,
  }),

  async beforeMount() {
    this.scrollToTop();
    let postCode = localStorage.getItem("PostalCode");
    this.PostalCodes = JSON.parse(postCode);
    this.quote.cityCode = this.PostalCodes;
    if (!localStorage.getItem("PetPolicyList")) {
      this.$router.push({ name: "Home" });
    }

    if (localStorage.getItem("phone")) {
      this.quote.phone = localStorage.getItem("phone")
    }

    if (localStorage.getItem("InfoDetails")) {
      let infoDetails = localStorage.getItem("InfoDetails");
      this.infos = JSON.parse(infoDetails);
      this.quote.firstname = this.infos.customerInfo.FirstName;
      this.quote.Lastname = this.infos.customerInfo.LastName;
      //this.quote.Email=this.infos.customerInfo.Email;
      this.quote.Address = this.infos.customerInfo.Address1;
      this.quote.Address2 = this.infos.customerInfo.Address2;
      this.quote.phone = this.infos.customerInfo.MobilePhone;
      this.quote.phoneAlt = this.infos.customerInfo.HomePhone;
      this.quote.City = this.infos.customerInfo.TownCity;
      this.quote.cityCode = this.infos.customerInfo.Postcode;
      //this.quote.TitleId=this.infos.customerInfo.TitleId;
      this.quote.SecondaryEmail = this.infos.customerInfo.SecondaryEmail;
      this.quote.SecondaryFirstName =
        this.infos.customerInfo.SecondaryFirstName;
      this.quote.SecondaryLastName = this.infos.customerInfo.SecondaryLastName;
      this.quote.SecondaryHomePhone =
        this.infos.customerInfo.SecondaryHomePhone;
      this.quote.SecondaryMobilePhone =
        this.infos.customerInfo.SecondaryMobilePhone;
      //this.quote.SecondaryTitleId=this.infos.customerInfo.SecondaryTitleId;
      this.quote.province = this.infos.province
        ? this.infos.province
        : "Select Province";
    }

    let getPolicyValue = localStorage.getItem("PetPolicyList");
    let quoteInfo = JSON.parse(getPolicyValue);
    quoteInfo.petInfo.petPolicyList.forEach((quotes) => {
      this.petsInfo.push(quotes);
    });

    this.getQuotetotal();
    localStorage.removeItem("PaymentMethod");
    this.queValue = this.$cookies.get("hubspotutk");
  },
  mounted() {
    // if (this.quote.AdditionalPetowner) {
    //   var chkYes = document.getElementById("AdditionalPetowner_True");
    //   chkYes.checked = true;
    // } else {
    //   var chkNo = document.getElementById("AdditionalPetowner_False");
    //   chkNo.checked = true;
    // }
  },
  methods: {
    upper(e) {
      e.target.value = e.target.value.toUpperCase();
    },
    removeQuestion() {
      let getPolicyValue = localStorage.getItem("PetPolicyList");
      let uoteInfo = JSON.parse(getPolicyValue);
      let policyList = uoteInfo.petInfo.petPolicyList;
      uoteInfo.petInfo.petPolicyList = policyList;
      localStorage.setItem("PetPolicyList", JSON.stringify(uoteInfo));
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    submitForm(quote) {
      
      this.errors = [];
      if (!quote.firstname) this.errors.push("First name required");
      if (!quote.Lastname) this.errors.push("Last name required");
      if (!quote.Address) this.errors.push("Address required");
      if (!quote.City) this.errors.push("City name required");
      if (!quote.cityCode) this.errors.push("Postal code required");
      if (!quote.phone) this.errors.push("Mobile number required");
      // if (quote.AdditionalPetowner) {
      //   if (!quote.SecondaryFirstName) {
      //     this.errors.push("Additional Pet Owner First name is required.");
      //     return;
      //   }
      //   if (!quote.SecondaryLastName) {
      //     this.errors.push("Additional Pet Owner Last name is required.");
      //     return;
      //   }
      // }
      if (quote.province === "Select Province")
        this.errors.push("Please select province");
      // if(this.petsInfo[0].questions[2].selected===false){
      //   if(this.petsInfo[0].questions[2].VetName==="" || this.petsInfo[0].questions[2].VetName===undefined){
      //     this.errors.push("Please Select Pet's Veterinarian..");
      //   }
      // }
      if (
        this.quote.cityCode != null &&
        this.quote.cityCode != undefined &&
        this.quote.cityCode != ""
      ) {
        this.quote.cityCode = this.quote.cityCode.replace(/\s/g, "");
        var x = this.quote.cityCode.slice(0, 3);
        var xy = this.quote.cityCode.slice(3); //quote.zip.match(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/);
        this.quote.cityCode = x + " " + xy;
      } else {
        this.isLogged = false;
        this.error = "Postal code required";
        return;
      }
      if (this.selectData === false) {
        this.petsInfo.forEach((petData) => {
          const dm = petData;
          console.log(dm);
          if (
            petData.questions[0].selected === false ||
            petData.questions[1].selected === false
          ) {
            this.selectData = false;
            this.errors
              .push("Please select pet's health questions")
              .slice(0, 1);
          }

          //if(petData.questions[4].selected===false){

          if (petData.questions[4].other === false) {
            //  if(petData.questions[2].VetName==="" || petData.questions[2].VetName===undefined ){
            //   this.selectData=false;
            //    this.errors.push("Please Select Pet's Veterinarian..").slice(0,1);
            //  }else{
            //    this.selectData=true;
            //  }
            this.selectData = true; // ths is just for now
          } else {
            this.selectData = true;
            petData.questions[4].selected = false;
          }
        });
      }
      // if(quote.firstname && quote.Lastname && quote.Address && quote.City && quote.phone && quote.cityCode && quote.province!='Select Province' && (this.petsInfo[0].questions[2].VetName!="" || this.petsInfo[0].questions[4].other===true) && this.selectData===true){

      if (
        quote.firstname &&
        quote.Lastname &&
        quote.Address &&
        quote.City &&
        quote.phone &&
        quote.cityCode &&
        quote.province != "Select Province" &&
        this.selectData === true
      ) {
        this.isLogged = true;
        this.loader();
        try {
          let customerinfo = {
            Address1: "",
            Address2: "",
            Email: "",
            FirstName: "",
            HomePhone: "",
            LastName: "",
            MobilePhone: "",
            Postcode: "",
            TownCity: "",
            TitleId: "",
            SecondaryEmail: "",
            SecondaryFirstName: "",
            SecondaryLastName: "",
            SecondaryHomePhone: "",
            SecondaryMobilePhone: "",
            SecondaryTitleId: "",
          };
          customerinfo.FirstName = quote.firstname;
          customerinfo.LastName = quote.Lastname;
          //customerinfo.Email=quote.Email;
          customerinfo.Address1 = quote.Address;
          customerinfo.Address2 = quote.Address2;
          customerinfo.MobilePhone = quote.phone.replace(
            /(\d{3})(\d{3})(\d{4})/,
            "$1-$2-$3"
          );
          if (
            customerinfo.HomePhone != null &&
            customerinfo.HomePhone != undefined &&
            customerinfo.HomePhone != ""
          ) {
            customerinfo.HomePhone = quote.phoneAlt.replace(
              /(\d{3})(\d{3})(\d{4})/,
              "$1-$2-$3"
            );
          } else {
            customerinfo.HomePhone = "";
          }
          customerinfo.TownCity = quote.City;
          customerinfo.Postcode = quote.cityCode;
          //customerinfo.TitleId=quote.TitleId;
          customerinfo.SecondaryEmail = quote.SecondaryEmail;
          customerinfo.SecondaryFirstName = quote.SecondaryFirstName;
          customerinfo.SecondaryLastName = quote.SecondaryLastName;
          customerinfo.SecondaryHomePhone = quote.SecondaryHomePhone;
          customerinfo.SecondaryMobilePhone = quote.SecondaryMobilePhone;
          //customerinfo.SecondaryTitleId=quote.SecondaryTitleId;
          let userInfo = {
            customerInfo: customerinfo,
            province: this.quote.province,
          };
          this.petsInfo.forEach((petData) => {
            if (petData.questions[2].Id === "") {
              petData.questions[2].Id = 0;
            }
          });
          let petDeatils = this.petsInfo;
          let getPolicyValue = localStorage.getItem("PetPolicyList");
          let quoteInfo = JSON.parse(getPolicyValue);
          quoteInfo.petInfo.petPolicyList = [];
          quoteInfo.petInfo.petPolicyList = petDeatils;

          localStorage.setItem("PetPolicyList", JSON.stringify(quoteInfo));
          localStorage.setItem("InfoDetails", JSON.stringify(userInfo));
          this.$router.push({ name: "payment" });
        } catch (error) {
          if (error && error.response && error.response.status === 401) {
            this.isLogged = false;
            this.$toast(error.response, {
              styles: {
                background: "Coral",
              },
              type: "error",
              positionY: "top",
            });
          } else {
            this.error = error.message;
            this.$toast(error.message, {
              styles: {
                background: "Coral",
              },
              type: "error",
              positionY: "top",
            });
          }
        }
      }
    },

    async getall(search) {
      this.loading = true;
      this.loader();
      if (search.length >= 5) {
        try {
          this.isLogged = true;
          let url = config.api.url + "VetSearch?" + "searchTerms=" + search;
          const response = await axios.post(url);
          const results = response.data;
          this.veterinarians = results.map((vet) => ({
            id: vet.id,
            practiceName: vet.practiceName,
            address1: vet.address1,
            address2: vet.address2,
            address3: vet.address3,
            townCity: vet.townCity,
            regionCounty: vet.regionCounty,
            postalCode: vet.postalCode,
            isEditable: vet.isEditable,
          }));
          this.veterinarians = this.veterinarians.sort((a, b) =>
            a.practiceName > b.practiceName ? 1 : -1
          );
        } catch (error) {
          if (error && error.response && error.response.status === 401) {
            this.isLogged = false;
            this.allveters = [];
          } else {
            this.error = error.message;
            this.allveters = [];
          }
        } finally {
          this.loading = false;
        }
      }
    },

    setVetcode(vet, index) {
      this.petsInfo[index].questions[2].Id = "41046";

      if (vet.id) {
        this.petsInfo[index].questions[2].Id = vet.id;
        this.petsInfo[index].questions[2].VetName = vet.practiceName;
        this.showDiv = false;
        document.getElementById("other" + index).checked = false;
      }
    },

    Vetsearch(item) {
      if (item.length >= 4) {
        this.getall(item);
      } else {
        this.veterinarians = [];
      }
    },

    showlist() {
      this.showDiv = true;
    },

    hidelist() {
      this.showDiv = false;
    },
    HideDiv() {
      this.quote.AdditionalPetowner = false;
      this.quote.SecondaryFirstName = "";
      this.quote.SecondaryLastName = "";
      // var dvtext = document.getElementById("displaycheck");
      // dvtext.style.display = "none";
    },

    ShowHideDiv() {
      this.quote.AdditionalPetowner = true;
      // var dvtext = document.getElementById("displaycheck");
      // dvtext.style.display = "inline-flex";
    },
    onChange(event) {
      console.log(event.target.value);
      this.quote.province = event.target.value;
    },

    onChangeChekBox(event, id) {
      if (event.target.checked === true) {
        if (event.target.id != "") {
          let name = this.$refs["petDistance_" + id];
          name.classList.remove("hidee");
          document.getElementById("other" + id).checked = false;
        }
      } else {
        if (event.target.id != "") {
          this.petsInfo[id].questions[2].Id = "";
          this.petsInfo[id].questions[2].VetName = "";
          let name = this.$refs["petDistance_" + id];
          name.classList.add("hidee");
        }
      }
    },

    onChangeChekBoxother(event, id) {
      if (event.target.checked === true) {
        if (event.target.id != "") {
          let name = this.$refs["petDistance_" + id];
          name.classList.add("hidee");
          document.getElementById("inlineCheck1_" + id).checked = false;

          // this.petsInfo[id].questions[2].Id="";
          this.petsInfo[id].questions[2].VetName = "";
        }
      } else {
        if (event.target.id != "") {
          // let name= this.$refs["inlineCheck1_"+id];
          //name.checked=true;
        }
      }
    },
    onChangeChekBoxotherup(event, id) {
      if (event.target.checked === true) {
        if (event.target.id != "") {
          // this.petsInfo[id].questions[2].Id="";
          this.petsInfo[id].questions[2].VetName = "";
        }
      } else {
        if (event.target.id != "") {
          // let name= this.$refs["inlineCheck1_"+id];
          //name.checked=true;
        }
      }
    },

    onChangeTreatmentYes(event, id) {
      if (event.target.id != "") {
        let name = this.$refs["dvtext_" + id];
        name.classList.remove("none");
      }
    },

    onChangeTreatmentNo(id) {
      this.petsInfo[id].questions[1].messageIfYes = "";
      let name = this.$refs["dvtext_" + id];
      name.classList.add("none");
    },

    getQuotetotal() {
      let getPolicyValue = localStorage.getItem("PetPolicyList");
      let quoteInfo = JSON.parse(getPolicyValue);
      let policeslist = quoteInfo.petInfo.petPolicyList;
      //let QuoteTotal=0;
      policeslist.forEach((policy) => {
        policy.policyList.forEach((policyes) => {
          if (policyes.selected === true) {
            this.QuoteTotal += policyes.recurringMonthly;
          }
        });
      });
    },

    loader() {
      this.selected = "circular";
      this.code = `<loader object="${this.styling.objectcolor}" color1="${this.styling.animationcolor}" color2="${this.styling.animationcolor2}" size="${this.styling.size}" speed="${this.styling.speed}" bg="${this.styling.bgcolor}" objectbg="${this.styling.objectbg}" opacity="${this.styling.opacity}" disableScrolling="${this.styling.disableScrolling}" name="${this.selected}"></loader>`;
      setTimeout(() => {
        this.selected = "";
      }, this.value * 2000);
    },
  },
};
</script>
<style>
.none {
  display: none !important;
}
.hidee {
  display: none !important;
}
</style>

